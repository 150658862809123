import React from 'react';
import CustomError from '../CustomError';
import Translate from '../Translate';

export interface IInlineError {
    showError: boolean;
    messageKey: string;
}

const InlineError = (props: IInlineError) => {
    return <CustomError
        showError={props.showError}
        content={<Translate text={props.messageKey}/>} 
    />;
};

export default InlineError;
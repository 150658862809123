import React from 'react';
import Translate from '../../Common/Translate';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import Tooltip from '../Tooltip';
import {changeFlow, FlowType} from '../../../store/reducers/sagaSlice';
import {changeSavePaymentMethod} from '../../../store/reducers/linkDataSlice';
import {savePaymentMethodSelector} from '../../../store/selectors/linkDataSelectors';

export interface ISavePaymentMethodCheckboxProps {
    id: string;

    savePaymentMethod: boolean,
    changeSavePaymentMethod: typeof changeSavePaymentMethod;
    changeFlow: typeof changeFlow;
}

export interface ISavePaymentMethodCheckboxState {
    checked: boolean;
}

class SavePaymentMethodCheckbox extends React.Component<ISavePaymentMethodCheckboxProps, ISavePaymentMethodCheckboxState> {

    constructor(props: ISavePaymentMethodCheckboxProps) {
        super(props);

        this.state = {
            checked: false
        }
    }

    componentDidMount() {
        this.setState({checked: this.props.savePaymentMethod})
    }

    render() {
        return (
            <div className="formCheckboxContainer">
                <label className="formCheckbox">
                    <Translate text={`paymentMethod.${this.props.id}`}/>
                    <input type="checkbox"
                           id="savePaymentMethod"
                           checked={this.state.checked}
                           onChange={this.onSavePaymentMethodChange}/>
                    <span className="checkmark"/>
                </label>
                <div className="tooltipContainer">
                    <Tooltip infoKey={this.props.id}/>
                </div>
            </div>
        );
    }

    private  onSavePaymentMethodChange = (e: any) => {
        this.setState({checked: e.target.checked});

        this.props.changeSavePaymentMethod(e.target.checked);
        const flow = e.target.checked ?
            FlowType.PM_PROVIDED_BY_USER_AND_SAVED : FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED;
        this.props.changeFlow(flow);
    };
}

export default connect(
    (state: RootState) => {
        return {
            savePaymentMethod: savePaymentMethodSelector(state),
        };
    },
    {
        changeFlow,
        changeSavePaymentMethod
    }
)(SavePaymentMethodCheckbox);


import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    contactEmailSelector,
    orderSelector,
    paymentMethodTypeSelector
} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import {PaymentMethodType} from '../../../model/PaymentMethodType';
import Translate from '../../Common/Translate';
import PaymentMethodDetails from '../../Common/PaymentMethodDetails';
import OrderDetails from '../../Common/OrderDetails';
import BackButton from '../../Common/BackButton';
import ContactEmailInput from '../../Common/ContactEmailInput';
import { languageSelector } from '../../../store/selectors/sagaSelectors';
import Loader from 'react-loader-spinner';
interface IExternalConfirmationProps {
    onConfirm: () => void;
    isProcessing: boolean;
}

interface IConnectedConfirmationProps {
    order: IOrder | null;
    contactEmail: string | null;
    paymentMethodType: PaymentMethodType;
    orderDetailsConfig?: any | null;
}

interface IConfirmationProps extends
    IExternalConfirmationProps,
    IConnectedConfirmationProps {
}

class Confirmation extends React.Component<IConfirmationProps> {

    render() {
        return (
            <React.Fragment>
                <h1 className="header title"><Translate text={`step.paymentConfirmation.title`}/></h1>

                <div className="orderMainDetailsContainer">
                <OrderDetails config={ this.getOrderDetailsConfig() }/>
                </div>
                <div className="paymentMethodDetailsContainer">
                    <label><Translate text={`step.paymentConfirmation.${this.props.paymentMethodType}`}/></label>
                    <PaymentMethodDetails />
                </div>

                { this.renderContactEmail() }

                { this.renderNote() }

                { this.renderSubmitButton() }

                <BackButton />
            </React.Fragment>
        );
    }

    private getOrderDetailsConfig() {
        const defaultOrderDetailsConfig = {amount: {class: 'center'}};
        return this.props.orderDetailsConfig ? this.props.orderDetailsConfig : defaultOrderDetailsConfig;
    }

    private renderContactEmail() {
        if (!this.props.order?.contactRequest && this.props.paymentMethodType === PaymentMethodType.CARD) {
            return null;
        }

        return (
            <div className='mb-20 bb-01'>
                <ContactEmailInput />
            </div>
        );
    }

    private renderNote() {
        if (!this.props.order || !this.props.order.confirmationPageNote ) {
            return null;
        }

        return (
            <div className='noteContainer mb-20'>
                { this.props.order.confirmationPageNote }
            </div>);
    }

    private isDisabled() {
        if (this.props.isProcessing) {
            return true;
        }

        if (this.props.order?.contactRequest && !this.props.contactEmail) {
            return true;
        }

        if (this.props.paymentMethodType === PaymentMethodType.SEPA_DEBIT && !this.props.contactEmail) {
            return true;
        }

        return false;
    }

    private renderSubmitButton(){
        if(!this.props.isProcessing){
            return <div>
                 <button id="btnCapturePayment"
                        className={"btn btn-primary mb-20"}
                        onClick={this.props.onConfirm}
                        disabled={this.isDisabled()}><Translate text={`button.confirm`}/>
                </button>
            </div>
        }else{
            return <div className="btn">
                <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={40}
                    width={40}
                />
            </div>
        }
    }
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            contactEmail: contactEmailSelector(state),
            paymentMethodType: paymentMethodTypeSelector(state),
            language: languageSelector(state),
        };
    },
    {
    }
)(Confirmation);

import React from 'react';
import Translate from '../Translate';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {changeActiveStep} from '../../../store/reducers/sagaSlice';
import {activeLinkNameSelector, activeStepSelector} from '../../../store/selectors/sagaSelectors';
import {LinkName} from '../../../model/LinkName';
import {LinkStep} from '../../../model/LinkStep';
import {useInjection} from '../../../ioc';
import {ErrorHandlerService} from '../../../service';

export interface IBackButton {
    activeStep: LinkStep | null;
    activeLinkName: LinkName | null;
    changeActiveStep: typeof changeActiveStep;
}

const BackButton = (props: IBackButton) => {
    const errorHandlerService = useInjection(ErrorHandlerService);

    const back = () => {
        switch(props.activeLinkName) {
            case LinkName.Link2Pay: 
            case LinkName.PayMe:

                switch(props.activeStep) {
                    case LinkStep.Confirmation:
                    case LinkStep.CustomerIdentification:
                    case LinkStep.PaymentMethodList:
                            props.changeActiveStep(LinkStep.PaymentDetails);
                        return;

                    case LinkStep.CustomerVerification:
                        props.changeActiveStep(LinkStep.CustomerIdentification);
                        return;

                    default:
                        errorHandlerService.handleInternalError(`[BackButton]: Invalid LinkStep: ${props.activeStep}`);
                        return;
                }

            case LinkName.Link2Save:

                switch(props.activeStep) {
                    case LinkStep.Confirmation:
                    case LinkStep.CustomerIdentification:
                    case LinkStep.PaymentMethodList:
                        props.changeActiveStep(LinkStep.PaymentMethodSave);
                        return;

                    case LinkStep.CustomerVerification:
                        props.changeActiveStep(LinkStep.CustomerIdentification);
                        return;

                    case LinkStep.PaymentMethodAdd:
                        props.changeActiveStep(LinkStep.PaymentMethodList);
                        return;

                    default:
                        errorHandlerService.handleInternalError(`[BackButton]: Invalid LinkStep: ${props.activeStep}`);
                        return;
                }

            default:
                errorHandlerService.handleInternalError(`[BackButton]; Invalid LinkName: ${props.activeLinkName}`);
                return;
        }
    };

    return (
        <button className="btn btn-secondary"
                type="button"
                onClick={back}>
            <Translate text={`button.back`}/>
        </button>
    );
};

export default connect(
    (state: RootState) => {
        return {
            activeStep: activeStepSelector(state),
            activeLinkName: activeLinkNameSelector(state),
        };
    },
    {
        changeActiveStep,
    }
)(BackButton);
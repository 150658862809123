import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {activeStepSelector} from '../../../store/selectors/sagaSelectors';
import {LinkStep} from '../../../model/LinkStep';
import TsPayRedirect from '../../Common/Redirect';
import Translate from '../../Common/Translate';
import {useInjection} from '../../../ioc';
import {ErrorHandlerService} from '../../../service';
import PaymentDetails from '../PaymentDetails';
import PaymentConfirmation from '../PaymentConfirmation';
import {Redirect} from 'react-router';

export interface IStepManagerProps {
    activeStep: LinkStep | null;
}

const StepManager: FC<IStepManagerProps> = (props: IStepManagerProps) => {
    const errorHandlerService = useInjection(ErrorHandlerService);

    if (!props.activeStep) {
        return (<Translate text={'loading'}/>);
    }

    const getActiveStep = (activeStep: LinkStep | null) => {
        switch(activeStep) {
            case LinkStep.PaymentDetails:
                return <PaymentDetails />;
            case LinkStep.Confirmation:
                return <PaymentConfirmation/>;
            case LinkStep.Redirect:
                return <TsPayRedirect />;
            case LinkStep.Error:
                return <Redirect to={'/error'}/>;
            default:
                errorHandlerService.handleInternalError(`[StepManager]: active step name is invalid:${activeStep}`);
                return null;
        }
    };

    return getActiveStep(props.activeStep);
};

export default connect(
    (state: RootState) => {
        return {
            activeStep: activeStepSelector(state),
        };
    },
    {}
)(StepManager);

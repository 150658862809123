import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {paymentMethodSelector, paymentMethodTypeSelector} from '../../../store/selectors/linkDataSelectors';
import Translate from '../Translate/index';
import styles from './styles.module.scss';
import {PaymentMethodType} from '../../../model/PaymentMethodType';


interface IPaymentMethodDetailsProps {
    paymentMethod: any;
    paymentMethodType: PaymentMethodType;
}

class PaymentMethodDetails extends React.Component<IPaymentMethodDetailsProps> {

    render() {
        if (!this.props.paymentMethod || !this.props.paymentMethodType) {
            return null;
        }

        const pm = this.props.paymentMethod[this.props.paymentMethodType];

        const brand = pm && pm.brand ? pm.brand : 'sepaDebit';
        const exp = pm && pm.exp_month ? pm.exp_month + ' / ' + pm.exp_year : '';

        return (
            <div className={styles.pmContainer}>
                <div className={`${styles.pm} ${styles[brand]}`} />
                <div className={styles.pmDetails}>
                    <div>**** **** **** {pm.last4}</div>
                    { pm && pm.exp_month &&
                        <div className={styles.pmDetails}>
                            <Translate text={'step.paymentMethodList.expiry'}/>: {exp}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => {
        return {
            paymentMethod: paymentMethodSelector(state),
            paymentMethodType: paymentMethodTypeSelector(state),
        };
    },
    {
    }
)(PaymentMethodDetails);
import * as React from "react";
import Translate from '../Translate';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector, paymentMethodTypeSelector, redirectDataSelector, transactionCodeSelector} from '../../../store/selectors/linkDataSelectors';
import {cleanState} from '../../../store/reducers/linkDataSlice';
import {Redirect} from 'react-router-dom';
import styles from "./styles.module.scss";
import OrderDetails from '../OrderDetails';
import {IOrder} from '../../../model/IOrder';
import PaymentMethodDetails from '../PaymentMethodDetails';
import { PaymentMethodType } from "../../../model/PaymentMethodType";

interface IThankYouProps {
    order: IOrder | null;
    redirectData: any;
    cleanState: typeof cleanState;
    paymentMethodType: PaymentMethodType;
    transactionCode: string | null;
}

const CONTACT = '';

class ThankYou extends React.Component<IThankYouProps> {

    componentWillUnmount() {
        this.props.cleanState({});
    }

    render() {
        if (!this.props.order) {
            return <Redirect to={'/'} />;
        }
        let printUrl = '';
        switch(this.props.paymentMethodType) {
            case PaymentMethodType.CARD:
            {
                printUrl =`/r/pdf/${this.props.order?.type}/${this.props.order?.orderKey}/${this.props.redirectData?.expectKey}`;
                break;
            }

            case PaymentMethodType.SEPA_DEBIT: {
                printUrl = `/r/pdf/${this.props.order?.type}/${this.props.order?.orderKey}/${this.props.redirectData?.expectKey}`;
                break;
            }

            case PaymentMethodType.PAYPAL: {
                printUrl = `/r/pdf/${this.props.order?.type}/${this.props.order?.orderKey}/${this.props.redirectData?.chargeKey}`;
                break;
            }
        }

        return (
            <div className={`content ${styles.thankYouContainer}`}>
                <h1 className={styles.header}><Translate text={`step.thankYou.title`}/></h1>

                <h2 className={styles.subtitle}><Translate text={this.props?.order?.type?.toLowerCase() === 'link2save' ? `step.thankYou.subtitleLink2save` : `step.thankYou.subtitle` }/></h2>

                <div className={styles.orderDetailsContainerLabel}><Translate text={`step.thankYou.orderDetailsLabel`}/></div>
                <div className={ this.getOrderDetailsContainerClass() }>
                    <OrderDetails config={ this.getOrderDetailsConfig() }/>
                </div>
                <div className={styles.paymentMethodDetailsContainer}>
                    <div className={styles.label}><Translate text={`step.paymentConfirmation.${this.props.paymentMethodType}`}/></div>
                    <PaymentMethodDetails/>
                </div>
                <div>
                    {this.renderTransactionCode()}
                    <div className={styles.addebitoSarVisi}><Translate text={`step.thankYou.infoMessage`}/></div>
                    <a id="btnPrint"
                        className={"btn btn-primary mb-20"} 
                        href={printUrl}
                        target={"__blank"}
                        ><Translate text={`step.thankYou.print`}/>
                    </a>
                    {this.renderNewPaymentButton()}
                </div>
                {/*
                // contact link removed  
                <p className={styles.contactContainer}>
                    <span><Translate text={`step.thankYou.contact.label`}/></span>
                    <a className={styles.link} href={`mailto:${CONTACT}`}><Translate text={`step.thankYou.contact.link`}/></a>
                </p>
                */}
            </div>
        );
    }

    private getOrderDetailsConfig() {
        const defaultOrderDetailsConfig = {positionSubTitle: {class: 'under-thanks'}, amount: {class: 'center'}};
        if (this.props.order?.type === 'payme') {
            return {
                positionSubTitle: {class: 'under-thanks'},
                amount: {class: 'center'},
                title: {class: 'centerText'},
                subtitle: {class: 'centerText'},
                header: {class: 'centerText'},
                underOrderTitlesSeparator: true
            };
        } else {
            return defaultOrderDetailsConfig;
        }
    }

    private getOrderDetailsContainerClass() {
        if (this.props.order?.type === 'payme') {
            return styles.payMeOrderDetailsContainer;
        } else {
            return styles.orderDetailsContainer;
        }
    }

    private renderTransactionCode() {
        if (this.props.order?.type === 'payme') {
            return (
                <h2 className={`${styles.subtitle} ${styles.transactionCode}`}><Translate text={`step.thankYou.transactionCode`}/>{this.props.transactionCode}</h2>
            );
        }
    }

    private renderNewPaymentButton() {
        if (this.props.order?.type === 'payme') {
            const newPaymentOnCurrentOrderUrl = `/payme/${this.props.order?.orderKey}`;
            return (
                <a id="btnNewPayment"
                    className={"btn btn-secondary mb-20"} 
                    href={newPaymentOnCurrentOrderUrl}
                    target="_top"
                    ><Translate text={`step.thankYou.newPayment`}/>
                </a>
            );
        }
    }
}
export default connect(
    (state: RootState) => {
        return {
            redirectData: redirectDataSelector(state),
            order: orderSelector(state),
            paymentMethodType: paymentMethodTypeSelector(state),
            transactionCode: transactionCodeSelector(state)
        };
    },{
        cleanState
    }
)(ThankYou);

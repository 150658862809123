import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    contactEmailSelector,
    customerAddressSelector,
    customerIdSelector,
    customerNameSelector,
    orderSelector,
    paymentMethodSelector,
    paymentMethodTypeSelector,
    savePaymentMethodSelector,
} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import {Link2PayDaoService} from '../../../service/dao';
import {LinkStep} from '../../../model/LinkStep';
import {changeActiveStep} from '../../../store/reducers/sagaSlice';
import {changeRedirectData, ICustomerAddress} from '../../../store/reducers/linkDataSlice';
import {PaymentMethodType} from '../../../model/PaymentMethodType';
import {ErrorHandlerService} from '../../../service/errorHandlerService';
import {IConfirmIntent, ICreateExpect} from '../../../model/IExpect';
import {first, tap} from 'rxjs/operators';
import Confirmation from '../../Common/Confirmation';
import {useInjection} from '../../../ioc';

interface IPaymentConfirmationProps {
    order: IOrder | null;
    savePaymentMethod: boolean;
    customerId: string | undefined;
    customerName: string | null;
    customerAddress: ICustomerAddress | null;
    contactEmail: string | null;
    paymentMethod: any;
    paymentMethodType: PaymentMethodType;
    changeActiveStep: typeof changeActiveStep;
    changeRedirectData: typeof changeRedirectData;
}

const PaymentConfirmation: FC<IPaymentConfirmationProps> = (props: IPaymentConfirmationProps) => {
    const link2PayDaoService = useInjection(Link2PayDaoService);
    const errorHandlerService = useInjection(ErrorHandlerService);


    const [isProcessing, setIsProcessing] = React.useState(false);

    const createPayment = () => {

        if (isProcessing || !props.order || !props.order.orderKey) {
            return null;
        }

        setIsProcessing( true);

        const createPaymentIntentPayload: ICreateExpect = {
            orderKey: props.order.orderKey,
            paymentMethodType: props.paymentMethodType,
            paymentMethodId: props.paymentMethod.id,
            savePaymentMethod: props.savePaymentMethod,
        };

        if (props.customerId) {
            createPaymentIntentPayload.customerId = props.customerId;
        }

        if (props.contactEmail) {
            createPaymentIntentPayload.contactEmail = props.contactEmail;
        }

        return link2PayDaoService.createPaymentIntent(createPaymentIntentPayload).pipe(
            first(),
            tap((createPaymentIntentResp: any) => {
                switch(props.paymentMethodType) {
                    case PaymentMethodType.CARD:

                        return confirmPayment(
                            createPaymentIntentResp
                        );

                    case PaymentMethodType.SEPA_DEBIT:
                        return confirmPayment(
                            createPaymentIntentResp,
                            createPaymentIntentPayload.paymentMethodId,
                        );

                    default:
                        errorHandlerService.handleInternalError(`Payment method is invalid: ${props.paymentMethodType}`);
                        return;
                }
            })
        ).subscribe();
    };

    const confirmPayment = async (createPaymentIntentResp: any, paymentMethodId?: string) => {

        const optionalParams = paymentMethodId ? { payment_method: paymentMethodId} : undefined;

        const confirmPaymentIntentPayload: IConfirmIntent = {
            orderKey: props.order!.orderKey as string,
            paymentIntentId: createPaymentIntentResp.id,
            //todo manage error
            stripeStatus: "succeeded"//stripeServiceResp.error ? 'expectation_error' : stripeServiceResp.paymentIntent.status,
        };

        return link2PayDaoService.confirmPaymentIntent(
            confirmPaymentIntentPayload,
            createPaymentIntentResp.chargeKey
        ).pipe(
            first(),
            tap((confirmPaymentResp: any) => {
                // manage error
                /*if (stripeServiceResp.error) {
                    errorHandlerService.handleStripeError(
                        stripeServiceResp.error,
                        createPaymentIntentResp.id,
                        createPaymentIntentResp.chargeKey
                    );
                    return;
                }*/

                setIsProcessing( false);

                props.changeRedirectData(confirmPaymentResp);
                props.changeActiveStep(LinkStep.Redirect);
            })
        ).subscribe();
    };

    return (<Confirmation isProcessing={isProcessing} onConfirm={createPayment}/>);
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            customerName: customerNameSelector(state),
            customerAddress: customerAddressSelector(state),
            customerId: customerIdSelector(state),
            contactEmail: contactEmailSelector(state),
            paymentMethodType: paymentMethodTypeSelector(state),
            paymentMethod: paymentMethodSelector(state),
            savePaymentMethod: savePaymentMethodSelector(state),
        };
    },
    {
        changeActiveStep,
        changeRedirectData,
    }
)(PaymentConfirmation);

export enum LinkStep {
    PaymentDetails = "PaymentDetails",
    PaymentMethodSave = "PaymentMethodSave",
    CustomerIdentification = "CustomerIdentification",
    CustomerVerification = "CustomerVerification",
    PaymentMethodList = "PaymentMethodList",
    PaymentMethodAdd = "PaymentMethodAdd",
    Confirmation = "Confirmation",
    Error = "Error",
    Redirect = "Redirect"
}

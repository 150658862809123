import React, {FC} from 'react';
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {orderSelector, savePaymentMethodSelector} from '../../../../store/selectors/linkDataSelectors';
import {changeActiveStep, FlowType} from '../../../../store/reducers/sagaSlice';
import {changePaymentMethodType, changePaymentMethod} from '../../../../store/reducers/linkDataSlice';
import {LinkStep} from '../../../../model/LinkStep';
import {PaymentMethodType} from '../../../../model/PaymentMethodType';
import {IOrder} from '../../../../model/IOrder';
import CardForm, {ICardFormConfig} from '../../../Common/CardForm';
import {useInjection} from '../../../../ioc';
import {ErrorHandlerService} from '../../../../service';
import {flowSelector} from '../../../../store/selectors/sagaSelectors';

export interface ICardPaymentProps {
    order: IOrder | null;
    flow: FlowType;
    savePaymentMethod: boolean;
    changeActiveStep: typeof changeActiveStep;
    changePaymentMethod: typeof changePaymentMethod;
    changePaymentMethodType: typeof changePaymentMethodType;
}

const CardPayment:FC<ICardPaymentProps> = (props: ICardPaymentProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const errorHandlerService = useInjection(ErrorHandlerService);
    const [showError, setShowError] = React.useState(false);

    if (!stripe || !elements) {
        return null;
    }

    const handleSubmit = async (e: any) => {

        const card = elements.getElement(CardNumberElement);
        const paymentMethod = await stripe.createPaymentMethod({
            type: PaymentMethodType.CARD,
            card: card!,
        });

        // Throw error so CardForm knows to reset the isProcessing flag
        if (paymentMethod.error) {
            setShowError(true);
            const errorMessage = paymentMethod.error.message;
            if (errorMessage) {
                throw new Error(errorMessage);
            } else {
                throw new Error(`Unexpected stripe error: ${paymentMethod.error}`);
            }
        }

        props.changePaymentMethod(paymentMethod.paymentMethod);
        props.changePaymentMethodType(PaymentMethodType.CARD);


        switch(props.flow) {
            case FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED:
                props.changeActiveStep(LinkStep.Confirmation);
                return;

            case FlowType.PM_PROVIDED_BY_USER_AND_SAVED:
                props.changeActiveStep(LinkStep.CustomerIdentification);
                return;

            default:
                errorHandlerService.handleInternalError(`[CustomerVerification]: wrong flow:${props.flow}`);
                return null;


        }
    };

    const formConfig: ICardFormConfig = {
        showSavePaymentMethodCheckbox: false,
        requiredPayMeAmount: true
    };

    return (<CardForm config={formConfig}
                      showCardError={showError}
                      onCardFormSubmit={e => handleSubmit(e)}/>);
};

export default connect(
    (state: RootState) => {
        return {
            flow: flowSelector(state),
            order: orderSelector(state),
            savePaymentMethod: savePaymentMethodSelector(state),
        };
    },
    {
        changeActiveStep,
        changePaymentMethod,
        changePaymentMethodType,
    }
)(CardPayment);


import React, {FC} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import Translate from '../../Common/Translate';
import InlineError from '../../Common/InlineError';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {credentialSelector, orderSelector, payMeOrderAmountSelector,} from '../../../store/selectors/linkDataSelectors';
import SavePaymentMethodCheckbox from '../SavePaymentMethodChackbox';
import { IOrder } from '../../../model/IOrder';
import Loader from 'react-loader-spinner';

export interface ICardFormConfig {
    showSavePaymentMethodCheckbox?: boolean;
    requiredPayMeAmount?: boolean;
}

export interface ICardFormProps {
    className?: string;

    order: IOrder | null,
    credential: string | null;

    config?: ICardFormConfig;
    showCardError: boolean;
    showFundingCardError?: boolean;

    
    payMeOrderAmount?: number | null;

    onCardFormSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<null | undefined>;
}



const CardForm:FC<ICardFormProps> = (props: ICardFormProps) => {
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                height: '40em',
                color: props.order?.customTheme?.textColor || '#32325d',
                fontFamily: '"Roboto',
                fontSmoothing: "antialiased",
                lineHeight: "4em",
                fontSize: "14px",
                "::placeholder": {
                    color: props.order?.customTheme?.hintColor || '#aab7c4'
                },
            },
            invalid: {
                color: "#d82829",
                iconColor: "#d82829",
            },
        },
    };
    // State
    const [isCardNumberValid, setIsCardNumberValid] = React.useState(false);
    const [isCardBrandAllowed, setIsCardBrandAllowed] = React.useState(true);
    const [isCardExpiryValid, setIsCardExpiryValid] = React.useState(false);
    const [isCardCvcValid, setIsCardCvcValid] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);

    const handleCardNumberChange = (e: any) => {
        const isNumberValid = e.complete;
        setIsCardNumberValid(isNumberValid);
        if(isNumberValid) {
            const isAllowedBrand = props.order?.cardBrands ? props.order?.cardBrands?.includes(e.brand) : true;
            setIsCardBrandAllowed(isAllowedBrand);
        }
    };
    const handleCardExpiryChange = (e: any) => {
        setIsCardExpiryValid(e.complete);
    };
    const handleCardCvcChange = (e: any) => {
        setIsCardCvcValid(e.complete);
    };

    const renderSaveCardCheckbox = () => {
        if (!props.config || !props.config.showSavePaymentMethodCheckbox) {
            return null;
        }

        return (<SavePaymentMethodCheckbox id={'saveCard'}/>)
    };

    const isButtonDisabled = () => {
        if (isProcessing) {
            return true;
        }

        if(props.config?.requiredPayMeAmount && !props.payMeOrderAmount) {
            return true;
        }

        if (!isCardNumberValid || !isCardBrandAllowed || !isCardExpiryValid || !isCardCvcValid) {
            return true;
        }

        return false;
    };

    const onCardFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsProcessing(true);

        try {
            await props.onCardFormSubmit(e);
        } catch (e) {
            setIsProcessing(false);
        }
    };

    const renderSubmitButton = () => {
        if(!isProcessing){
            return <div>
                 <button id="btnSavePaymentMethod"
                    className="btn btn-primary"
                    type="submit"
                    disabled={isButtonDisabled()}>
                <Translate text={`button.confirm`}/>
            </button>
            </div>
        }else{
            return <div className="btn">
                <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={50}
                    width={50}
                />
            </div>
        }
    }

    return (
        <form className={props.className} onSubmit={ (e) => onCardFormSubmit(e)}>

            <div className="formControl">
                <label>
                    <Translate text={`paymentMethod.cardNumber`}/>
                </label>
                <div className="stripeElementContainer cardNumber">
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardNumberChange}/>
                </div>
                <InlineError showError={props.showCardError} messageKey={'error.invalidCard'}/>
                <InlineError showError={!isCardBrandAllowed} messageKey={'error.invalidBrand'}/>
                <InlineError showError={props.showFundingCardError || false} messageKey={'error.invalidFundingType'}/>
            </div>

            <div className="formRow">
                <div className="formControl">
                    <label>
                        <Translate text={`paymentMethod.cardExpiry`}/>
                    </label>
                    <div className="stripeElementContainer cardExpiry">
                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardExpiryChange}/>
                    </div>
                </div>

                <div className="formControl">
                    <label>
                        <Translate text={`paymentMethod.cardCvc`}/>
                    </label>
                    <div className="stripeElementContainer cardCvc">
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardCvcChange}/>
                    </div>
                </div>
            </div>

            { renderSaveCardCheckbox() }

            { renderSubmitButton() }
            
        </form>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            credential: credentialSelector(state),
            payMeOrderAmount: payMeOrderAmountSelector(state)
        };
    },
    {}
)(CardForm);


import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    chargePaymentRefSelector,
    contactEmailSelector,
    customerAddressSelector,
    customerIdSelector,
    customerNameSelector,
    orderSelector,
    paymentMethodSelector,
    paymentMethodTypeSelector,
    payMeOrderAmountSelector,
    savePaymentMethodSelector,
} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import {PayMeDaoService} from '../../../service/dao';
import {LinkStep} from '../../../model/LinkStep';
import {changeActiveStep} from '../../../store/reducers/sagaSlice';
import {changeRedirectData, changeTransactionCode, ICustomerAddress} from '../../../store/reducers/linkDataSlice';
import {PaymentMethodType} from '../../../model/PaymentMethodType';
import {ErrorHandlerService} from '../../../service/errorHandlerService';
import {IConfirmIntent, ICreateExpectForPayMe} from '../../../model/IExpect';
import {first, tap} from 'rxjs/operators';
import Confirmation from '../../Common/Confirmation';
import {useStripe} from '@stripe/react-stripe-js';
import {useInjection} from '../../../ioc';

interface IPaymentConfirmationProps {
    order: IOrder | null;
    savePaymentMethod: boolean;
    customerId: string | undefined;
    customerName: string | null;
    customerAddress: ICustomerAddress | null;
    contactEmail: string | null;
    paymentMethod: any;
    paymentMethodType: PaymentMethodType;
    payMeOrderAmount: number | null;
    chargePaymentRef: string | null;
    changeActiveStep: typeof changeActiveStep;
    changeRedirectData: typeof changeRedirectData;
    changeTransactionCode: typeof changeTransactionCode;
}

const PaymentConfirmation: FC<IPaymentConfirmationProps> = (props: IPaymentConfirmationProps) => {
    const stripe = useStripe();
    const payMeDaoService = useInjection(PayMeDaoService);
    const errorHandlerService = useInjection(ErrorHandlerService);


    const [isProcessing, setIsProcessing] = React.useState(false);

    if (!stripe) {
        return null;
    }

    const createPayment = () => {

        if (isProcessing || !props.order || !props.order.orderKey) {
            return null;
        }

        setIsProcessing( true);

        const amount = props.payMeOrderAmount;
        if(!amount) {
            return null;
        }
        
        const createPaymentIntentPayload: ICreateExpectForPayMe = {
            orderKey: props.order.orderKey,
            paymentMethodType: props.paymentMethodType,
            paymentMethodId: props.paymentMethod.id,
            savePaymentMethod: props.savePaymentMethod,
            amount: amount
        };

        if (props.customerId) {
            createPaymentIntentPayload.customerId = props.customerId;
        }

        if (props.contactEmail) {
            createPaymentIntentPayload.contactEmail = props.contactEmail;
        }

        if (props.chargePaymentRef) {
            createPaymentIntentPayload.chargePaymentRef = props.chargePaymentRef;
        }

        return payMeDaoService.createPaymentIntent(createPaymentIntentPayload).pipe(
            first(),
            tap((createPaymentIntentResp: any) => {
                switch(props.paymentMethodType) {
                    case PaymentMethodType.CARD:

                        return confirmStripePayment(
                            stripe.confirmCardPayment,
                            createPaymentIntentResp
                        );

                    default:
                        errorHandlerService.handleInternalError(`Payment method is invalid: ${props.paymentMethodType}`);
                        return;
                }
            })
        ).subscribe();
    };

    const confirmStripePayment = async (stripeService: any, createPaymentIntentResp: any, paymentMethodId?: string) => {

        const optionalParams = paymentMethodId ? { payment_method: paymentMethodId} : undefined;

        return stripeService(createPaymentIntentResp.client_secret, optionalParams)
            .then((stripeServiceResp: any) => {

                const confirmPaymentIntentPayload: IConfirmIntent = {
                    orderKey: props.order!.orderKey as string,
                    paymentIntentId: createPaymentIntentResp.id,
                    stripeStatus: stripeServiceResp.error ? 'expectation_error' : stripeServiceResp.paymentIntent.status,
                };

                return payMeDaoService.confirmPaymentIntent(
                    confirmPaymentIntentPayload,
                    createPaymentIntentResp.chargeKey
                ).pipe(
                    first(),
                    tap((confirmPaymentResp: any) => {
                        if(confirmPaymentResp.transactionCode) {
                            props.changeTransactionCode(confirmPaymentResp.transactionCode);
                        }

                        if (stripeServiceResp.error) {
                            errorHandlerService.handleStripeError(
                                stripeServiceResp.error,
                                createPaymentIntentResp.id,
                                createPaymentIntentResp.chargeKey
                            );
                            return;
                        }

                        setIsProcessing( false);

                        props.changeRedirectData(confirmPaymentResp);
                        props.changeActiveStep(LinkStep.Redirect);
                    })
                ).subscribe();
            })
    };

    return (<Confirmation isProcessing={isProcessing} onConfirm={createPayment}
                orderDetailsConfig={{
                    amount: {class: 'center'},
                    title: {class: 'centerText'},
                    subtitle: {class: 'centerText'},
                    header: {class: 'centerText'},
                    underOrderTitlesSeparator: true,
                    underAmountSeparator: true
                }}
            />
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            customerName: customerNameSelector(state),
            customerAddress: customerAddressSelector(state),
            customerId: customerIdSelector(state),
            contactEmail: contactEmailSelector(state),
            paymentMethodType: paymentMethodTypeSelector(state),
            paymentMethod: paymentMethodSelector(state),
            savePaymentMethod: savePaymentMethodSelector(state),
            payMeOrderAmount: payMeOrderAmountSelector(state),
            chargePaymentRef: chargePaymentRefSelector(state)
        };
    },
    {
        changeActiveStep,
        changeRedirectData,
        changeTransactionCode
    }
)(PaymentConfirmation);

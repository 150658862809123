import React, {FC} from 'react';
import Confirmation from '../../Common/Confirmation';
import {useInjection} from '../../../ioc';
import {Link2SaveService} from '../../../service';
import {tap} from 'rxjs/operators';

interface IPaymentMethodSaveConfirmationProps {
}

const PaymentMethodSaveConfirmation: FC<IPaymentMethodSaveConfirmationProps> = (props: IPaymentMethodSaveConfirmationProps) => {
    const link2SaveService = useInjection(Link2SaveService);


    const [isProcessing, setIsProcessing] = React.useState(false);

    /*if (!stripe) {
        return null;
    }

    const createPayment = () => {
        setIsProcessing( true);
        link2SaveService.createAndConfirmExpect(stripe).pipe(
            tap(()=> setIsProcessing( false))
        ).subscribe();

    };*/

    const createPayment = () => {
        setIsProcessing( true);
    };

    return (<Confirmation isProcessing={isProcessing} onConfirm={createPayment}/>);
};

export default PaymentMethodSaveConfirmation;
